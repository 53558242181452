// @flow
import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AuthController } from "./libs/controllers";
import { Spinner } from "../src/commom-components";

const style = {
  spinner: {
    margin: "10px auto",
    textAlign: "center",
    verticalAlign: "middle",
    position: "absolute",
    top: "40%",
    left: "50%",
    marginLeft: "-4em",
  },
};

const PrivateRoute = (props) => {
  const {
    refreshAuthenticatedFlag,
    isAuthenticated,
    signInError,
    component: Component,
    ...rest
  } = props;
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    refreshAuthenticatedFlag().then((resp) => {
      setCheckingAuth(false);
      console.log("VOLTOU DO REFRESH: ", resp);
    });
  }, []);

  if (checkingAuth) {
    return (
      <div style={style.spinner}>
        <Spinner size="2em" />
      </div>
    );
  }

  return <Route {...rest} component={Component} />;
};

const mapStateToProps = (state) => {
  const {
    auth: { server, firebase },
  } = state;

  return {
    isAuthenticated: server.isAuthenticated && firebase.isAuthenticated,
    therapistUID: firebase && firebase.uid,
    signInError: server.signInError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  refreshAuthenticatedFlag: () =>
    AuthController.refreshAuthenticatedFlag(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

PrivateRoute.defaultProps = {
  component: null,
  render: () => null,
  children: null,
  exact: false,
  strict: false,
  location: {},
  sensitive: false,
};
