import { AuthTypes } from "../types";

export const setLoginServerSuccess = () => ({
  type: AuthTypes.LOGIN_SERVER_SUCCESS,
  payload: null,
});

export const setLoginServerFailed = (errorMessage: string) => ({
  type: AuthTypes.LOGIN_SERVER_FAILED,
  payload: { errorMessage },
});

export const setLogoutServerSuccess = () => ({
  type: AuthTypes.LOGOUT_SERVER_SUCCESS,
  payload: null,
});

/* FIREBASE AUTH ACTION CREATORS */
export const setLoginFirebaseSuccess = (uid: string) => ({
  type: AuthTypes.LOGIN_FIREBASE_SUCCESS,
  payload: { uid },
});

export const setLoginFirebaseFailed = () => ({
  type: AuthTypes.LOGIN_FIREBASE_FAILED,
  payload: null,
});

export const setLogoutFirebaseSuccess = () => ({
  type: AuthTypes.LOGOUT_FIREBASE_SUCCESS,
  payload: null,
});

export const saveFirebaseAuthData = (firebaseAuth: FirebaseAuth) => ({
  type: AuthTypes.SAVE_FIREBASE_AUTH,
  payload: firebaseAuth,
});
