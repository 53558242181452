import React from "react";
import styles from "./Button.module.css";
import { Colors } from "../../assets/color/Colors";

export const Button = (props) => {
  const { title, func, icon, color } = props;
  return (
    <button
      style={{
        backgroundColor: color,
        color: color === Colors.third ? "black" : "white",
        borderRadius: 5,
      }}
      onClick={func}
    >
      {title}
    </button>
  );
};

export const FormButtom = (props) => {
  const { title, color, func } = props;
  return (
    <div className={styles.formButton}>
      <button
        type="submit"
        className={styles.button}
        style={{
          backgroundColor: color,
          color: color === Colors.third ? "black" : "white",
          borderRadius: 5,
        }}
        onClick={func}
      >
        {title}
      </button>
    </div>
  );
};
