import React from "react";
import styles from "./TextInput.module.css";
import { Field, ErrorMessage } from "formik";
import MaskedInput from "react-text-mask";

export const TextInput = (props) => {
  const { label, name, type, placeholder, setItem, checkEmail, mask } = props;
  return (
    <div className={styles.input}>
      <label className={styles.label}>{label}</label>
      <Field
        className={styles.field}
        type={type}
        name={name}
        placeholder={placeholder}
        onBlur={(e) => {
          checkEmail({ email: e.target.value });
          setItem(e.target.value);
        }}
        render={
          mask
            ? ({ field }) => {
                return (
                  <MaskedInput
                    mask={mask}
                    {...field}
                    id={name}
                    onBlur={(e) => setItem(e.target.value)}
                    placeholder={placeholder}
                  />
                );
              }
            : null
        }
      />
      <ErrorMessage component="div" className={styles.error} name={name} />
    </div>
  );
};

export const Checkbox = (props) => {
  const { label, name, terms, setItem } = props;
  return (
    <div>
      <div className={styles.checkbox} style={{ margin: "px 0px" }}>
        <input
          className={styles.checkfield}
          type={"checkbox"}
          name={name}
          onChange={(e) => setItem(e.target.checked)}
        />
        <label className={styles.checkfieldText}>
          {label}
          {terms ? (
            <span>
              <a
                href={"https://moodar.com.br/termos_de_uso.html"}
                style={{ textDecorationLine: "underline" }}
              >
                Termos de uso
              </a>{" "}
              e{" "}
              <a
                href={"https://moodar.com.br/politica_de_privacidade.html"}
                style={{ textDecorationLine: "underline" }}
              >
                Política de Privacidade
              </a>
            </span>
          ) : null}
        </label>
      </div>
      <div>
        <ErrorMessage component="div" className={styles.error} name={name} />
      </div>
    </div>
  );
};

export const SelectInput = (props) => {
  const { label, options, setItem, name } = props;
  return (
    <div className={styles.dropdown}>
      <label for={name}>{label}</label>
      <Field
        component="select"
        name={name}
        onClick={(e) => setItem(e.target.value)}
        className={styles.select}
      >
        {options?.map((item) => {
          return <option value={item}>{item}</option>;
        })}
      </Field>
      <ErrorMessage component="div" className={styles.error} name={name} />
    </div>
  );
};

TextInput.defaultProps = {
  checkEmail: () => null,
};
