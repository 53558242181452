import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./Login.module.css";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  TextInput,
  Button,
  Checkbox,
  FormButtom,
  Spinner,
} from "../../commom-components";
import { logo } from "../../assets/images/images";
import { Colors } from "../../assets/color/Colors";
import { AuthController } from "../../libs/controllers";

const Login = (props) => {
  const { signIn, history } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [fetching, setFecthing] = useState(false);

  const getValidatorSchema = () =>
    Yup.object().shape({
      email: Yup.string().email("Email Inválido").required("Campo obrigatório"),
      password: Yup.string().required("Campo obrigatório"),
    });

  const cameFromRequest = history.location.state?.cameFromRequest;
  const eventId = history.location.state?.eventId;

  const handleRoute = () => {
    if (cameFromRequest) {
      return `/eventos/${eventId}`;
    } else {
      return "/home";
    }
  };

  return (
    <div className={`${styles.container}`}>
      <Formik
        enableReinitialize
        initialValues={{
          email,
          password,
        }}
        validationSchema={() => getValidatorSchema()}
        onSubmit={(values) => {
          const { email, password } = values;
          const data = { username: email, password };
          setFecthing(true);
          signIn(data)
            .then((res) => {
              history.push(handleRoute());
            })
            .catch((err) => setFecthing(false));
        }}
      >
        {() => (
          <Form className={styles.form}>
            <img src={logo} className={styles.logo} />
            <TextInput
              name={"email"}
              type={"email"}
              placeholder={"Email predileto (ou de trabalho)"}
              label={"E-mail"}
              setItem={setEmail}
            />
            <TextInput
              name={"password"}
              type={"password"}
              placeholder={"Secreta (que você lembra)"}
              label={"Senha"}
              setItem={setPassword}
            />

            {fetching ? (
              <Spinner />
            ) : (
              <div className={styles.buttons}>
                <FormButtom
                  color={Colors.primary}
                  title={"ENTRAR"}
                  type={"submit"}
                />
                <Button
                  color={Colors.secondary}
                  title={"AINDA NÃO TEM CONTA?"}
                  func={() => history.push("/signup")}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signIn: (data) => AuthController.signIn(dispatch, data),
});

export default connect(null, mapDispatchToProps)(Login);
