import React from "react";

const Benchmark = () => {
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "#313341",
        paddingTop: 100,
        paddingBottom: 100,
        marginTop: 100,
      }}
    >
      <div className="container">
        <div className="row">
          <div class="col-md-8 col-sm-8" style={{ textAlign: "left" }}>
            <div class="heading-title">
              <span style={{ color: "#FFFFFF" }}>Benchmark</span>
              <h2
                class="darkcolor bottom20"
                style={{ fontWeight: 600, color: "white" }}
              >
                Inspire-se em empresas de referência
              </h2>
              <p
                class="title darkcolor"
                style={{ fontWeight: 600, color: "white" }}
              >
                Descubra o que as empresas que você admira estão priorizando no
                desenvolvimento de seus colaboradores e de suas culturas, tome
                decisões mais inteligentes!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benchmark;
