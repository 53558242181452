// @flow
import SignUp from "../views/signup/SignUp";
import Login from "../views/login/Login";
import Events from "../views/events-detail/Events";
import Main from "../views/main/Main";

export const routes: Array<{
  path: string,
  component: React$ComponentType<*>,
  isPrivate: boolean,
}> = [
  { path: "/signup", component: SignUp, isPrivate: false },
  { path: "/login", component: Login, isPrivate: false },
  { path: "/home", component: Main, isPrivate: true },
  { path: "/eventos/:id", component: Events, isPrivate: true },
];
