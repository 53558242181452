import {
  cesar,
  logo_eats,
  logo_escale,
  logo_distrito,
  logo_indorama,
  logo_inove,
  logo_jj,
  logo_urja,
  logo_pitang,
  logo_portodigital,
  logo_wavin,
  logo_itbc,
  neurotech,
} from "../assets/images/images";

import {
  evento_diversidade,
  evento_lider,
  evento_saude,
} from "../assets/images/images";

export const offlineOptions = [
  {
    href: "https://moodar.com.br/covid-19.html",
    title: "Plataforma Corporativa",
  },
  { href: "https://moodar.com.br/colaborador.html", title: "Terapia Online" },
  {
    href: "https://moodar.com.br/empresa.html",
    title: "Desenvolvimento & Cultura",
    bold: true,
  },

  { href: "https://moodar.com.br/colaborador.html", title: "App Psicólogo(a)" },
];

export const eventsOptions = [];

export const onlineOptions = [
  { href: "#trending", title: "Trending" },
  { href: "#performance", title: "Performance" },
  { href: "#lideranca", title: "Liderança" },
  { href: "#saude", title: "Saúde" },
  { href: "#diversidade", title: "Diversidade" },
  { href: "#divesidade", title: "Log Out" },
];

export const parceiras_images = [
  cesar,
  logo_distrito,
  logo_eats,
  logo_escale,
  logo_indorama,
  logo_urja,
  logo_pitang,
  logo_portodigital,
  logo_wavin,
  neurotech,
];

export const cards = [
  {
    id: 1,
    image: evento_saude,
    type: "Palestra Online",
    title: "Inclusão e Diversidade",
    description: "Um dia as coisas mudarão no mundo moderno",
  },
  {
    id: 2,
    image: evento_diversidade,
    type: "Palestra Online",
    title: "Inclusão e Diversidade",
    description: "Um dia as coisas mudarão no mundo moderno",
  },
  {
    id: 3,
    image: evento_lider,
    type: "Palestra Online",
    title: "Inclusão e Diversidade",
    description: "Um dia as coisas mudarão no mundo moderno",
  },
  {
    id: 4,
    image: evento_diversidade,
    type: "Palestra Online",
    title: "Inclusão e Diversidade",
    description: "Um dia as coisas mudarão no mundo moderno",
  },
  {
    id: 5,
    image: evento_saude,
    type: "Palestra Online",
    title: "Inclusão e Diversidade",
    description: "Agora, as coisas mudarão no mundo moderno",
  },
  {
    id: 6,
    image: evento_lider,
    type: "Workshop",
    title: "Saúde e bem estar",
    description: "Alguém sabe das coisas que estão mudando no mundo moderno",
  },
];

export const carouselOptions = [
  { id: "#trending", title: "Trending", events: cards },
  { id: "#performance", title: "Performance", events: cards },
  { id: "#lideranca", title: "Liderança", events: cards },
  { id: "#saude", title: "Saúde", events: cards },
  { id: "#diversidade", title: "Diversidade", events: cards },
];
