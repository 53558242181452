import firebase from "firebase";

export const signUp = (email, password) => {
  console.log(email, password, "ops");
  return firebase.auth().createUserWithEmailAndPassword(email, password);
};

export const logIn = (credentials) => {
  const { username: email, password } = credentials;
  console.log("DATA", credentials);
  return firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => firebase.auth().signInWithEmailAndPassword(email, password));
};

export const isAuthenticated = () => {
  return new Promise((resolve, reject) =>
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        resolve(user.uid);
      } else {
        reject();
      }
    })
  );
};

export const logOut = () => firebase.auth().signOut();
