export const logo = require("./logo.png");
export const evento_diversidade = require("./evento_diversidade.png");
export const evento_saude = require("./evento_saude.png");
export const evento_lider = require("./evento_lider.png");

//Parceiras
export const cesar = require("./logos_parceiras/cesar.png");
export const logo_distrito = require("./logos_parceiras/logo_distrito.png");
export const logo_eats = require("./logos_parceiras/logo_eats.png");
export const logo_escale = require("./logos_parceiras/logo_escale.png");
export const logo_indorama = require("./logos_parceiras/logo_indorama.png");
export const logo_inove = require("./logos_parceiras/logo_inove.png");
export const logo_jj =
  "https://elevewebapp-files.s3.sa-east-1.amazonaws.com/static/images/cuidandojj.png";
export const logo_loft = require("./logos_parceiras/logo_loft.png");
export const logo_pitang = require("./logos_parceiras/logo_pitang.png");
export const logo_portodigital = require("./logos_parceiras/logo_portodigital.png");
export const logo_urja = require("./logos_parceiras/logo_urja.png");
export const logo_wavin = require("./logos_parceiras/logo_wavin.png");
export const logo_itbc = require("./logos_parceiras/logo-itbc.png");
export const neurotech = require("./logos_parceiras/neurotech.png");

//Footer
export const iugu = require("./iugu.png");
export const pag_amex = require("./pag_amex.png");
export const pag_boleto = require("./pag_boleto.png");
export const pag_dinners = require("./pag_dinners.png");
export const pag_elo = require("./pag_elo.png");
export const pag_master = require("./pag_master.png");
export const pag_visa = require("./pag_visa.png");
