import React, { useState, useEffect } from "react";
import styles from "./Nav.module.css";
import { logo } from "../../assets/images/images";
import { connect } from "react-redux";
import { NavHashLink as Link } from "react-router-hash-link";
import { offlineOptions, eventsOptions } from "../../utils/utils";
import { AuthController } from "../../libs/controllers";
import { routes } from "../../utils/Routes";

const getNavList = (list, isAuth) => {
  const route = window.location.href.includes("eventos");

  const titles = list.map((option) => {
    return {
      href: `#${option[1].title.toLowerCase()}`,
      title: option[1].title,
    };
  });

  return route ? eventsOptions : isAuth ? titles : offlineOptions;
};

const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth",
  });
};

const List = ({ isAuth, listStyles, events, logout, history, setOpen }) => {
  const nav = [{ href: "#trending", title: "Trending" }].concat(
    getNavList(Object.entries(events), isAuth)
  );

  console.log("NAV", nav);

  const [current, setCurrent] = useState("");

  document.addEventListener("scroll", () =>
    window.scrollY > 100 && isAuth
      ? applyStyle(nav, window.scrollY, setCurrent)
      : setCurrent(undefined)
  );

  const isOutsideWebpage = (route) => route.includes("https://");

  return (
    <div className={styles.menu}>
      {nav.map((option) => {
        return (
          <li>
            <a
              onClick={() => (setOpen ? setOpen(false) : null)}
              className={listStyles ? styles.listBorder : null}
              class="nav-link"
              target="_self"
              style={
                (option.bold ? { fontWeight: "bold" } : null,
                current === option.href
                  ? {
                      backgroundColor: "#2E9BE2",
                      color: "white",
                      borderRadius: "20px",
                    }
                  : null)
              }
              href={isOutsideWebpage(option.href) ? option.href : undefined}
            >
              {!isOutsideWebpage(option.href) ? (
                <Link
                  smooth
                  to={`${option.href}`}
                  scroll={(el) => scrollWithOffset(el, 155)}
                >
                  {option.title}
                </Link>
              ) : (
                <p>{option.title}</p>
              )}
            </a>
          </li>
        );
      })}
      {isAuth ? (
        <a
          className={listStyles ? styles.listBorder : null}
          class="nav-link"
          target="_self"
          style={{ color: "black" }}
          onClick={() =>
            logout().then((res) => {
              console.log("foi daqui??");
              history.push("/home");
            })
          }
        >
          Logout
        </a>
      ) : (
        <a
          className={listStyles ? styles.listBorder : null}
          class="nav-link"
          target="_self"
          style={{ color: "black" }}
          onClick={() => history.push("/login")}
        >
          Login
        </a>
      )}
    </div>
  );
};

const applyStyle = (nav, scroll, setCurrent) => {
  if (scroll < 300) {
    setCurrent(nav[0]?.href);
  } else if (scroll >= 300 && scroll < 800) {
    setCurrent(nav[1]?.href);
  } else if (scroll >= 801 && scroll < 1300) {
    setCurrent(nav[2]?.href);
  } else if (scroll >= 1301 && scroll < 1700) {
    setCurrent(nav[3]?.href);
  } else if (scroll > 1700) {
    setCurrent(nav[4]?.href);
  }
};

const Nav = (props) => {
  const { history, events, isAuth, logout } = props;

  const isMobile = window.screen.width <= 1024;
  const [open, setOpen] = useState(false);
  const [navClass, setNavClass] = useState(styles.nav);
  const [listStyles, setListStyle] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scroll = window.scrollY;
      if (scroll < 150) {
        setNavClass(styles.nav);
        setListStyle(false);
      } else {
        setNavClass(`${styles.nav} ${styles.fixedNav}`);
        setListStyle(true);
      }
    });
  });

  return (
    <div className={` ${navClass}`}>
      <div className={isMobile ? "container-fluid" : "container"}>
        <div className={styles.navrow}>
          <div className={styles.row}>
            <div className={styles.left} onClick={() => history.push("/home")}>
              <img src={logo} width="120px" height="35px" alt="logo" />
            </div>

            <div className={styles.right}>
              {isMobile ? (
                <button
                  className={`fas fa-bars ${styles.burguer}`}
                  onClick={() => setOpen(open ? false : true)}
                ></button>
              ) : (
                <List
                  events={events}
                  isAuth={isAuth}
                  history={history}
                  logout={logout}
                />
              )}
            </div>
          </div>
          {isMobile && open ? (
            <div className={styles.menuMobile}>
              <List
                events={events}
                isAuth={isAuth}
                history={history}
                logout={logout}
                setOpen={setOpen}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    events: { list, trending },
    auth: {
      server: { isAuthenticated: serverAuth },
      firebase: { isAuthenticated: firebaseAuth },
    },
  } = state;

  const isAuth = serverAuth && firebaseAuth;

  return { events: list, trending, serverAuth, isAuth };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => AuthController.logout(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);

Nav.defaultProps = {
  setOpen: () => null,
};
