import React from "react";
import styles from "./EventInfo.module.css";

const icons = ["timer", "work_outline", "groups", "menu_book"];

const InfoIconDetail = (props) => {
  const { event, index } = props;
  return (
    <div className={`${styles.item} d-flex flex-row align-items-center`}>
      <i className="material-icons">{icons[index]}</i>
      <p>{event}</p>
    </div>
  );
};

const getDuration = (duration) =>
  duration === 0 ? "Sem tempo estimado" : `${duration} mins`;

const getMaterial = (material) =>
  material === 0
    ? "Sem material de apoio"
    : material === 1
    ? `${material} material de apoio`
    : `${material} materiais de apoio`;

const getAudience = (audience) =>
  audience === 0 ? "Sem limite de audiência" : `${audience} participantes`;

const getPresenter = (presenter) => `Apresentado por ${presenter}`;

const EventInfo = (props) => {
  const {
    event: { duration, presenter, audience, material },
  } = props;

  const event = {
    duration: getDuration(duration),
    presenter: getPresenter(presenter),
    audience: getAudience(audience),
    material: getMaterial(material),
  };

  const eventArray = Object.values(event);

  return (
    <div className={styles.detail}>
      {eventArray.map((info, index) => {
        return <InfoIconDetail event={info} index={index} />;
      })}
    </div>
  );
};

export default EventInfo;
