import React from "react";

const EventAbout = (props) => {
  const {
    event: { description },
  } = props;
  return (
    <div className="eventcontainer">
      <div className="abouttheevent">
        <h4>Sobre esta ação</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default EventAbout;
