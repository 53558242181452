import React, { useEffect, useState } from "react";
import Nav from "../../commom-components/nav/Nav";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Carousel from "../../commom-components/carousel/Carousel";
import styles from "./Main.module.css";
import {
  Benchmark,
  HowItWorks,
  RegisterToAccess,
  FirstDescription,
  Partnerships,
  Contact,
} from "../static-views";
import Footer from "../footer/Footer";
import Filter from "../../commom-components/filter/Filter";
import { EventController } from "../../libs/controllers";
import { v4 as uuidV4 } from "uuid";

const Main = (props) => {
  const {
    history,
    fetchEvents,
    saveEventDetail,
    fetchTrending,
    events,
    trending,
    isAuth,
  } = props;

  const isMobile = window.screen.width <= 450;

  const getTrending = async () => await fetchTrending();
  const getEvents = async () => await fetchEvents();

  useEffect(() => {
    getEvents();
    getTrending();
  }, []);

  const goToEventDetail = (history, event) => saveEventDetail(history, event);
  console.log("Trending", trending, "Events", events);
  return (
    <div>
      <Nav history={history} />
      <FirstDescription />
      <div className={isMobile ? `${styles.containerMobile}` : "container"}>
        <Carousel
          id={`trending`}
          title={"Trending"}
          cards={trending}
          history={history}
          func={goToEventDetail}
          key={uuidV4()}
        />
        {events.map((event, key) => {
          return (
            <Carousel
              id={`${event.title.toLowerCase()}`}
              title={event.title}
              cards={event.events}
              history={history}
              func={goToEventDetail}
              key={uuidV4()}
            />
          );
        })}
      </div>
      {!isAuth ? (
        <React.Fragment>
          <RegisterToAccess />
          <Partnerships />
          <HowItWorks />
          <Benchmark />
        </React.Fragment>
      ) : null}
      <Contact logged={isAuth} />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    events: { list, trending },
    auth: {
      server: { isAuthenticated: serverAuth },
      firebase: { isAuthenticated: firebaseAuth },
    },
  } = state;

  const isAuth = serverAuth && firebaseAuth;

  return { events: list, trending, isAuth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEvents: () => EventController.fetchEvents(dispatch),
    fetchTrending: () => EventController.fetchTrendingEvents(dispatch),
    saveEventDetail: (event, history) =>
      EventController.goToEventDetail(dispatch, event, history),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
