import { EventsAPI } from "../../server";
import { EventsActions } from "../redux/actions";

export const fetchEvents = async (dispatch) => {
  try {
    const response = await EventsAPI.fetchEvents();
    const events = Object.entries(response.events).map((item) => {
      return { title: item[0], events: item[1] };
    });
    dispatch(EventsActions.saveEvents(events));
  } catch (err) {
    throw err;
  }
};

export const fetchTrendingEvents = async (dispatch) => {
  try {
    const response = await EventsAPI.fetchTrendingEvents();
    dispatch(EventsActions.saveTrendingEvents(response.events));
    return response.events;
  } catch (err) {
    throw err;
  }
};

export const goToEventDetail = (dispatch, event, history) => {
  console.log("AUUUU", event, history);
  localStorage.setItem("event_id", event.id);
  dispatch(EventsActions.saveEventDetail(event));
  history.push(`/eventos/${event.id}`);
};

export const fetchEventDetail = async (dispatch, id) => {
  try {
    const response = await EventsAPI.fetchEventDetail(id);
    dispatch(EventsActions.saveEventDetail(response.event));
    return response.event;
  } catch (err) {
    throw err;
  }
};
