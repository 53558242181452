import { combineReducers } from "redux";

import UserReducer from "./UserReducer";
import EventsReducer from "./EventsReducer";
import AuthReducer from "./auth";

export default combineReducers({
  user: UserReducer,
  events: EventsReducer,
  auth: AuthReducer,
});
