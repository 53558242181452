import React from "react";

const Contact = ({ logged }) => {
  return !logged ? (
    <div>
      <section
        id="comece-agora"
        className="video-parallax padding_acompanhamento"
      >
        <div className="container">
          <div className="row" id="acompanhamento-big">
            <div className="col-md-8 col-sm-8">
              <div className="heading-title text-md-left text-center padding_bottom_acompanhamento">
                <span>Vamos conversar!</span>
                <h2 className="bottom25 darkcolor" style={{ fontWeight: 300 }}>
                  Quer saber mais?
                </h2>
                <p className="title darkcolor" style={{ fontWeight: 300 }}>
                  Se você quer saber mais sobre a nossa plataforma de
                  desenvolvimento, cultura ou benefícios, entra em contato com a
                  gente!
                </p>

                <div
                  className="pipedriveWebForms"
                  data-pd-webforms="https://webforms.pipedrive.com/f/2Vsz9h8R94ct8ao51MR0rYeouEGYcSWhhPDZvPIF2H2jogbh9gzIrsqGvtqpggU95"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <section
      id="comece-agora"
      className="padding_half "
      style={{ backgroundColor: "#212331" }}
    >
      <div className="container">
        <div className="row" id="acompanhamento-big">
          <div className="col-md-8 col-sm-8 image-container-acompanhamento-big">
            <div className="heading-title text-md-left text-center padding_bottom_acompanhamento">
              <p
                className="title whitecolor"
                style={{ fontWeight: 300, padding: "0 0 20 0" }}
              >
                Não encontrou o que procurava? Clique no botão abaixo para
                conversar com a nossa equipe e encontraremos um jeito de ajudar!
              </p>
              <a
                role="button"
                href="https://api.whatsapp.com/send?phone=5581991067299"
                target="_blank"
                onclick="return gtag_report_conversion('https://api.whatsapp.com/send?phone=5581991067299&text=Ol%C3%A1,%20eu%20gostaria%20de%20saber%20mais%20sobre%20a%20Moodar%20para%20empresas.')"
                className="button btn_whatsapp btnsecondary top20 small-action-anchor"
              >
                Falar com a Moodar
              </a>
            </div>
          </div>
          <div className="col-md-4 col-sm-4"></div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
