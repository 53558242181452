import React from "react";

const FirstDescription = () => {
  return (
    <div className="container" style={{ zIndex: -99 }}>
      <div class="row" style={{ margin: "60px 0" }}>
        <div className={`col-md-8 col-sm-8 `}>
          <h2 className={"darkcolor bottom20 fontbold"}>
            Encontre a próxima campanha para sua equipe
          </h2>
          <p className={`title darkcolor `}>
            Workshops, Palestras e Trilhas de Desenvolvimento
            <strong> desenhados e envelopados sob medida</strong> para encantar
            o(a) seu colaborador(a)!
          </p>
        </div>
      </div>
    </div>
  );
};

export default FirstDescription;
