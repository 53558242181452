import React from "react";

const EventTitle = (props) => {
  const {
    event: { title, subtitle, tags, action_type },
  } = props;
  return (
    <div className="headingofevent">
      <div className="typeofevent d-flex flex-row align-items-center">
        <i className="material-icons">cast_connected</i>
        <p>{action_type?.toUpperCase()}</p>
      </div>
      <h2 className="titleofevent">{title}</h2>
      <p className="subtitleofevent">{subtitle}</p>

      <p className="tagsofevent">
        {tags?.map((tag) => (
          <span>{tag} </span>
        ))}
      </p>
    </div>
  );
};

export default EventTitle;
