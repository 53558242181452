import React from "react";

const RegisterToAccess = () => {
  return (
    <div className="container">
      <h3 className={`pinkcolor bottom20 top40`} style={{ fontWeight: 600 }}>
        Cadastre-se para acessar todo o nosso portfólio e solicitar uma primeira
        ação gratuita!
      </h3>
    </div>
  );
};

export default RegisterToAccess;
