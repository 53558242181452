import { EventsTypes } from "../types";

export const saveEvents = (events) => ({
  type: EventsTypes.SAVE_EVENTS,
  payload: events,
});

export const clearEvents = () => ({
  type: EventsTypes.CLEAR_EVENTS,
  payload: {},
});

export const updateEvents = (events) => ({
  type: EventsTypes.UPDATE_EVENTS,
  payload: events,
});

export const saveEventDetail = (event) => ({
  type: EventsTypes.SAVE_EVENT_DETAIL,
  payload: event,
});

export const clearEventDetail = () => ({
  type: EventsTypes.CLEAR_EVENT_DETAIL,
  payload: {},
});

export const updateEventDetail = (events) => ({
  type: EventsTypes.UPDATE_EVENT_DETAIL,
  payload: events,
});

export const saveTrendingEvents = (events) => ({
  type: EventsTypes.SAVE_TRENDING_EVENTS,
  payload: events,
});

export const clearTrendingEvents = () => ({
  type: EventsTypes.CLEAR_TRENDING_EVENT,
  payload: {},
});

export const updateTrendingEvents = (events) => ({
  type: EventsTypes.UPDATE_TRENDING_EVENT,
  payload: events,
});
