import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import styles from "./SignUp.module.css";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Spinner,
  TextInput,
  Checkbox,
  FormButtom,
  SelectInput,
  Toast,
} from "../../commom-components";
import { Colors } from "../../assets/color/Colors";
import { AuthController } from "../../libs/controllers";
import * as Masks from "../../utils/Masks";
import { logo } from "../../assets/images/images";

const options = [
  "Quantidade de colaboradores",
  "0-49",
  "50-99",
  "100-199",
  "200-499",
  "500-999",
  "1000+",
];

const handleBodyToReq = (values) => {
  const { email } = values;
  return { ...values, username: email };
};

const SignUp = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState(undefined);
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [estimated_employees, setEstimatedEmployees] = useState("");
  const [role, setRole] = useState("");
  // const [checkterms, setCheck] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [fetching, setFecthing] = useState(false);
  const { signUp, history } = props;

  const cameFromRequest = history.location.state?.cameFromRequest;
  const eventId = history.location.state?.eventId;

  const handleRoute = () => {
    if (cameFromRequest) {
      return `/eventos/${eventId}`;
    } else {
      return "/home";
    }
  };

  const getValidatorSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(1, "Nome é obrigatório")
        .required("Campo obrigatório"),
      // phone: Yup.string().required("Telefone é obrigatório"),
      email: Yup.string().email("Email Inválido").required("Campo obrigatório"),
      company: Yup.string("Empresa inválida").required("Campo obrigatório"),
      role: Yup.string("Campo inválido").required("Campo inválido"),
      estimated_employees: Yup.string()
        .required("Campo inválido")
        .test(
          "match",
          "Campo inválido",
          (comp) => comp !== "Quantidade de colaboradores"
        ),
      password: Yup.string().required("Campo obrigatório"),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senhas não são iguais")
        .required("Confirme sua senha"),
      // check: Yup.bool().oneOf([true], "Atenção! Você deve aceitar os termos"),
    });

  return (
    <div className={styles.container}>
      <Formik
        enableReinitialize
        initialValues={{
          name,
          email,
          role,
          password,
          confirm_password,
          estimated_employees,
          company,
          // checkterms: true,
        }}
        validationSchema={() => getValidatorSchema()}
        onSubmit={(values) => {
          const body = handleBodyToReq(values);
          setFecthing(true);
          signUp(body)
            .then((res) => {
              history.push(handleRoute());
            })
            .catch((err) => {
              setFecthing(false);
              return Toast(err.message, "error", 4000);
            });
        }}
      >
        {() => (
          <Form className={styles.form}>
            <div className={styles.header}>
              <img src={logo} className={styles.logo} />
              <h4 className={styles.headerTitle}>Cadastre-se para continuar</h4>
            </div>
            <TextInput
              name={"name"}
              type={"text"}
              placeholder={"Nome completo"}
              setItem={setName}
            />

            <TextInput
              name={"email"}
              type={"email"}
              placeholder={"Email"}
              setItem={setEmail}
            />

            <TextInput
              name={"company"}
              type={"text"}
              placeholder={"Empresa"}
              setItem={setCompany}
            />

            <TextInput
              name={"role"}
              type={"text"}
              placeholder={"Cargo"}
              setItem={setRole}
            />

            <SelectInput
              name={"estimated_employees"}
              options={options}
              setItem={setEstimatedEmployees}
              placeholder={"Quantidade de colaboradores"}
            />

            {/* <TextInput
              name={"phone"}
              type={"text"}
              placeholder={"(DD) 99999-9999"}
              setItem={setPhone}
              mask={Masks.phoneMask}
            /> */}

            <TextInput
              name={"password"}
              type={"password"}
              placeholder={"Senha"}
              setItem={setPassword}
            />

            <TextInput
              name={"confirm_password"}
              type={"password"}
              placeholder={"Confirme sua senha"}
              setItem={setConfirmPassword}
            />

            {/* <Checkbox
              name={"check"}
              placeholder={"Você aceita os termos"}
              label={"Li e estou de acordo com os "}
              terms={true}
              setItem={setCheck}
            /> */}

            {fetching ? (
              <Spinner />
            ) : (
              <div className={styles.buttons}>
                <FormButtom
                  color={"#ea6a5c"}
                  title={"CRIAR CONTA"}
                  type={"submit"}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signUp: (data) => AuthController.signUp(dispatch, data),
});

export default connect(null, mapDispatchToProps)(SignUp);
