import fetchServer from "./Server";

export const fetchEvents = () => {
  return fetchServer({
    method: "GET",
    path: ["eventos", "corporate-actions"],
  });
};

export const fetchEventDetail = (id) => {
  return fetchServer({
    method: "GET",
    path: ["eventos", "corporate-actions", `${id}`],
  });
};

export const fetchTrendingEvents = () => {
  return fetchServer({
    method: "GET",
    path: ["eventos", "corporate-actions", "trending"],
  });
};
