export const SAVE_EVENTS = "SAVE_EVENTS";
export const CLEAR_EVENTS = "CLEAR_EVENTS";
export const UPDATE_EVENTS = "UPDATE_EVENTS";

export const SAVE_EVENT_DETAIL = "SAVE_EVENT_DETAIL";
export const CLEAR_EVENT_DETAIL = "CLEAR_EVENT_DETAIL";
export const UPDATE_EVENT_DETAIL = "UPDATE_EVENT_DETAIL";

export const SAVE_TRENDING_EVENTS = "SAVE_TRENDING_EVENTS";
export const CLEAR_TRENDING_EVENT = "CLEAR_TRENDING_EVENT";
export const UPDATE_TRENDING_EVENT = "UPDATE_TRENDING_EVENT";
