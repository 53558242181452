import React from "react";
import {
  pag_amex,
  pag_boleto,
  pag_dinners,
  pag_elo,
  pag_visa,
  pag_master,
  iugu,
} from "../../assets/images/images";
const Footer = () => {
  return (
    <footer
      id="site-footer"
      className="padding_half "
      style={{ backgroundColor: "#212331" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 text-left">
            <p
              className="copyrights whitecolor"
              style={{ padding: "0 0 20 0;" }}
            >
              Copyright &copy; 2020 Moodar. Todos os direitos reservados.
            </p>
            <p
              className="copyrights whitecolor"
              style={{ padding: "0 0 20 0;" }}
            >
              Rua do Apolo, 181 <br></br> Recife, PE 50030-220 <br></br>Brasil
              <br></br>
              <br></br>
              +55 81 3132 3000 | +55 81 9 9311 7696 <br></br>
              suporte@moodar.com.br
            </p>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <p
              className="copyrights whitecolor"
              style={{ padding: "0 0 20 0" }}
            >
              Pagamentos por
            </p>
            <img
              src={iugu}
              alt="logo"
              style={{ width: 120, padding: "0 0 20 0" }}
            />

            <div>
              <ul style={{ display: "inline" }}>
                <li style={{ display: "inline" }}>
                  <img src={pag_visa} style={{ width: 40, margin: 5 }} />
                </li>
                <li style={{ display: "inline" }}>
                  <img src={pag_master} style={{ width: 40, margin: 5 }} />
                </li>
                <li style={{ display: "inline" }}>
                  <img src={pag_amex} style={{ width: 40, margin: 5 }} />
                </li>
              </ul>
            </div>
            <div>
              <ul style={{ display: "inline" }}>
                <li style={{ display: "inline" }}>
                  <img src={pag_dinners} style={{ width: 40, margin: 5 }} />
                </li>
                <li style={{ display: "inline" }}>
                  <img src={pag_elo} style={{ width: 40, margin: 5 }} />
                </li>
                <li style={{ display: "inline" }}>
                  <img src={pag_boleto} style={{ width: 40, margin: 5 }} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
