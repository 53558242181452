import { AuthAPI } from "../../server";
import { FAuth } from "../firebase";
import { UserActions, AuthActions } from "../redux/actions/";

export const signUp = async (dispatch, data) => {
  const { email, password } = data;
  return FAuth.signUp(email, password)
    .then((firebaseResponse) => {
      return AuthAPI.signupParticipant({
        ...data,
        uid: firebaseResponse.user.uid,
      })
        .then((user) => {
          const { emailVerified, uid } = firebaseResponse.user;
          dispatch(AuthActions.setLoginServerSuccess());
          dispatch(AuthActions.saveFirebaseAuthData({ emailVerified, uid }));
          dispatch(AuthActions.setLoginFirebaseSuccess());
          dispatch(UserActions.saveUserProfile(user));
          localStorage.setItem("user", JSON.stringify(user.user_id));

          return user;
        })
        .catch((err) => {
          firebaseResponse.user.delete();
          throw err;
        });
    })
    .catch((err) => {
      throw err;
    });
};

export const signIn = async (dispatch, data) => {
  return AuthAPI.signinParticipant(data)
    .then((user) => {
      return FAuth.logIn(data).then((firebaseAuth) => {
        const { emailVerified, uid } = firebaseAuth.user;
        dispatch(AuthActions.setLoginServerSuccess());
        dispatch(AuthActions.setLoginFirebaseSuccess());
        dispatch(UserActions.saveUserProfile(user));
        dispatch(AuthActions.saveFirebaseAuthData({ emailVerified, uid }));
        localStorage.setItem("user", JSON.stringify(user.user_id));
        return user;
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const refreshAuthenticatedFlag = (dispatch) =>
  Promise.all([
    AuthAPI.isAuthenticated()
      .then((profile) => {
        console.log("profile!", profile);
        dispatch(UserActions.saveUserProfile(profile));
        dispatch(AuthActions.setLoginServerSuccess());
      })
      .catch((error) => {
        console.log("error no auth flag", error);
        dispatch(AuthActions.setLoginFirebaseFailed());
        dispatch(AuthActions.setLoginServerFailed());
        dispatch(AuthActions.setLogoutServerSuccess());
        dispatch(AuthActions.setLogoutFirebaseSuccess());
      }),
    FAuth.isAuthenticated()
      .then((uid) => {
        dispatch(AuthActions.setLoginFirebaseSuccess(uid));
        return uid;
      })
      .catch((error) => {
        dispatch(AuthActions.setLoginFirebaseFailed());
        dispatch(AuthActions.setLogoutServerSuccess());
        dispatch(AuthActions.setLogoutFirebaseSuccess());
      }),
  ])
    .then((resp) => {
      return resp[1];
    })
    .catch((err) => {
      console.log("err", err);
    });

export const logout = (dispatch) => {
  return AuthAPI.logout().then((server) => {
    return FAuth.logOut().then((firebase) => {
      dispatch(AuthActions.setLogoutServerSuccess());
      dispatch(AuthActions.setLogoutFirebaseSuccess());
      dispatch(UserActions.clearUserProfile());
    });
  });
};
