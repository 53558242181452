import React from "react";
import "./App.css";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { routes } from "./utils/Routes";
import PrivateRoute from "./PrivateRoute";
//
const App = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={routes[2].component} />
        {routes.map((r) => {
          const { isPrivate, path, ...props } = r;
          const Routable: React$ComponentType<*> = isPrivate
            ? PrivateRoute
            : Route;
          return <Routable key={path} path={path} {...props} />;
        })}
      </Switch>
    </HashRouter>
  );
};

export default App;
