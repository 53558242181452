import fetchServer from "./Server";

export const signupParticipant = (body: Object) => {
  return fetchServer({
    method: "POST",
    path: ["auth", "signup-hr"],
    body,
  });
};

export const signinParticipant = (body: Object) => {
  return fetchServer({
    method: "POST",
    path: ["auth", "login-hr"],
    body,
  });
};

export const isAuthenticated = () => {
  const path = ["auth", "rh-user-profile"];
  return fetchServer({ method: "GET", path });
};

export const logout = () => {
  const path = ["auth", "logout-hr"];
  return fetchServer({ method: "POST", body: {}, path });
};

export const retrieveSession = (authToken: string) => {
  return fetchServer({
    method: "GET",
    path: ["auth", "retrieve-session"],
    authToken,
  });
};

export const updateSession = (authToken: string, body: Object) => {
  return fetchServer({
    method: "POST",
    path: ["auth", "v2", "update-session"],
    authToken,
    body,
  });
};

export const retrieveCompanies = () => {
  return fetchServer({
    method: "GET",
    path: ["corporate", "companies"],
  });
};

export const verifyEmail = (body: Object) => {
  return fetchServer({
    method: "POST",
    path: ["auth", "verify-email"],
    body,
  });
};
