import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Card from "../cards/Card";
import styles from "./Carousel.module.css";
import { v4 as uuidV4 } from "uuid";

const Carousel = (props) => {
  const { id, title, cards, images, func, history } = props;
  const isFold = window.screen.width <= 280;
  const isMobile = window.screen.width <= 450;
  const isTablet = window.screen.width <= 768 && window.screen.width > 450;
  const isPro = window.screen.width <= 1024 && window.screen.width > 768;

  return cards ? (
    <div
      className={isMobile ? "" : "container"}
      style={{ margin: "20px 0" }}
      id={id}
    >
      <h3>{title}</h3>
      <OwlCarousel
        items={isFold ? 1 : isMobile ? 2 : isTablet ? 3 : isPro ? 4 : 5}
        margin={80}
      >
        {cards.map((card, key) => {
          return (
            <Card card={card} key={uuidV4()} func={() => func(card, history)} />
          );
        })}
        <div></div>
      </OwlCarousel>
    </div>
  ) : images ? (
    <div className={`container-fluid ${styles.fluid}`}>
      <div className={`container`}>
        <OwlCarousel
          items={isFold ? 1 : isMobile ? 2 : isTablet ? 3 : isPro ? 4 : 5}
          margin={80}
          dots={false}
        >
          {images.map((img) => {
            return (
              <div className={styles.logo}>
                <img className={styles.images} src={img} />
              </div>
            );
          })}
          <div></div>
        </OwlCarousel>
      </div>
    </div>
  ) : null;
};

Carousel.defaultProps = {
  func: () => null,
};
export default Carousel;
