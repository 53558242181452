import React from "react";

const list = [
  "Selecione uma ação",
  "Indique suas preferências de horário",
  "Valide o(a) palestrante indicado(a) pela Moodar",
  "Receba as artes de divulgação da Ação",
  "Participe!",
  "Acompanhe os resultados",
];

const HowItWorks = () => {
  return (
    <div className={"container"} style={{ marginTop: 100 }}>
      <div class="col-md-8 col-sm-8" style={{ textAlign: "left" }}>
        <div class="heading-title">
          <span>Como Funciona</span>
          <h2 class="darkcolor bottom20" style={{ fontWeight: 600 }}>
            Nunca foi tão fácil <strong>agir</strong>
          </h2>
          <p
            class="title darkcolor"
            style={{ fontWeight: 300, padding: "0 0 20px 0" }}
          >
            Implemente ações de excelência em desenvolvimento emocional e
            cultura na sua empresa com{" "}
            <strong>
              poucos cliques, investimento enxuto e o mínimo esforço operacional
            </strong>
            . Ah! <strong>Nós medimos o resultado para você!</strong>
          </p>

          {list.map((item) => (
            <button
              className={`collapsible-comofunciona`}
              style={{ padding: "10px 10px" }}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
