import React from "react";
import styles from "../Events.module.css";

const EventImage = (props) => {
  const {
    event: { image },
  } = props;

  return (
    <div className="image-event">
      <div
        class="img"
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: 12,
          objectFit: "cover",
        }}
      ></div>
    </div>
  );
};

export default EventImage;
