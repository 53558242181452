// @flow
import React, { useState } from "react";
import Modal from "react-modal";
import { Button } from "../buttons/Button";
import styles from "./Modal.module.css";

export const CustomModal = (props) => {
  const { modal, setModal, component } = props;

  return (
    <Modal
      isOpen={modal}
      onRequestClose={() => setModal(false)}
      className={styles.modal}
      style={{
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        },
      }}
    >
      <div className={styles.close}>
        <i className={"fas fa-times"} onClick={() => setModal(false)}></i>
      </div>
      {component()}
    </Modal>
  );
};

export const ConfirmModal = (props) => {
  const { modal, setModal, func, title } = props;

  return (
    <Modal
      isOpen={modal}
      className={styles.modal}
      onRequestClose={() => setModal(false)}
      style={{
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          overflow: "none",
        },
      }}
    >
      <div>
        <h4>{title}</h4>
        <div className={styles.buttons}>
          <Button
            title={"Sim"}
            color={"#3cb2df"}
            func={func}
            icon={"fas fa-check"}
          />
          <Button
            title={"Não"}
            color={"#96a5ab"}
            func={() => setModal(false)}
            icon={"fas fa-times"}
          />
        </div>
      </div>
    </Modal>
  );
};
