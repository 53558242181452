import React, { useEffect } from "react";
import Nav from "../../commom-components/nav/Nav";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EventTitle from "./components/EventTitle";
import EventInfo from "./components/EventInfo";
import EventAbout from "./components/EventAbout";
import EventApplication from "./components/EventApplication";
import EventImage from "./components/EventImage";
import { EventController } from "../../libs/controllers";
import { v4 as uuidV4 } from "uuid";

import Carousel from "../../commom-components/carousel/Carousel";
import { cards } from "../../utils/utils";

const EventDetail = (props) => {
  const {
    history,
    event,
    fetchEventDetail,
    fetchTrending,
    saveEventDetail,
    trending,
    isAuth,
  } = props;

  const fullPath = window.location.href;
  const splits = fullPath.split("/");
  // console.log(splits)
  const event_id = splits.splice(-1)[0];

  const goToEventDetail = (history, event) => saveEventDetail(history, event);

  useEffect(() => {
    fetchEventDetail(event_id);
    fetchTrending();
  }, []);

  return (
    <div>
      <Nav history={history} />
      <div className={`container`} style={{ marginBottom: "100px" }}>
        <div className={`row`}>
          <div className="col-md-8 col-sm-8 eventtextcontainer">
            <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
              <div className={"eventcontainer"}>
                <EventTitle event={event} />
                <EventInfo event={event} />
                <EventAbout event={event} />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <EventImage event={event} />
            <EventApplication event={event} isAuth={isAuth} history={history} />
          </div>
        </div>
        <Carousel
          title={"Trending"}
          id={"trending"}
          cards={trending}
          history={history}
          func={(item) => goToEventDetail(item, history)}
          key={uuidV4()}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    events: { selected_event, trending },
    auth: {
      server: { isAuthenticated: serverAuth },
      firebase: { isAuthenticated: firebaseAuth },
    },
  } = state;

  const isAuth = serverAuth && firebaseAuth;

  return { event: selected_event, trending, isAuth };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTrending: () => EventController.fetchTrendingEvents(dispatch),
    fetchEventDetail: (id) => EventController.fetchEventDetail(dispatch, id),
    saveEventDetail: (event, history) =>
      EventController.goToEventDetail(dispatch, event, history),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventDetail)
);
