import React, { useState } from "react";
import styles from "./EventApplication.module.css";
import { Button, CustomModal, ConfirmModal } from "../../../commom-components/";
import { Colors } from "../../../assets/color/Colors";
import RequestEventModal from "./RequestEventModal/RequestEventModal";

const AvailableCredits = ({ credits, loggedIn }) => {
  return (
    <p className={styles.availableCredits}>
      {loggedIn
        ? credits > 0
          ? // ? `Você possui ${credits} créditos disponíveis`
            `É só preencher o formulário :)`
          : `Você não possui créditos`
        : "Assine a Moodar para ter acesso a este e a vários outros benefícios!"}
    </p>
  );
};

const CurrentCredits = ({ credits, loggedIn }) => {
  return (
    <div class="d-flex flex-row align-items-center">
      <i class="material-icons">{loggedIn ? "bookmark" : "lock"}</i>
      <h4 className={styles.currentCredits}>
        {loggedIn
          ? // ? `${credits} créditos`
            `Solicite agora este evento!`
          : `Desbloqueie a experiência`}
      </h4>
    </div>
  );
};

const UpperRow = (props) => {
  const { loggedIn, credits } = props;
  return (
    <div className={styles.upperRow}>
      <CurrentCredits credits={credits} loggedIn={loggedIn} />
      <AvailableCredits credits={credits} loggedIn={loggedIn} />
    </div>
  );
};

const Action = (props) => {
  const { desc, title, func, color } = props;
  return (
    <div className={`${styles.action} d-flex flex-row align-items-center`}>
      <div className={styles.description}>
        <p>{desc}</p>
      </div>
      <div className={styles.button}>
        <Button title={title} func={func} color={color} />
      </div>
    </div>
  );
};

const requestEvent = (auth, event, history) => {
  console.log("auth", auth, "event", event, "history", history);
  if (auth) {
  } else {
    localStorage.setItem("requestedEvent", event.id);
    return history.push("/signup");
  }
};

const DownRow = (props) => {
  const { loggedIn, event, history, setRequestModal, setTfModal } = props;
  return loggedIn ? (
    <div className={styles.downRow}>
      <Action
        title={"Solicitar"}
        desc={"Solicie esta ação para a sua empresa"}
        func={() => setRequestModal(true)}
        color={Colors.secondary}
      />
      {/* <Action
        title={"Compartilhar"}
        desc={"Compartilhe esta ação com outra empresa"}
        func={() => console.log("popo")}
        color={Colors.third}
      /> */}
    </div>
  ) : (
    <div className={styles.downRow}>
      <Action
        title={"Solicitar"}
        desc={"Solicite uma ação experimental"}
        func={() => setRequestModal(true)}
        color={Colors.secondary}
      />
    </div>
  );
};

const CallRequestModal = (history, setModal) => (
  <RequestEventModal history={history} setModal={setModal} />
);

const EventApplication = (props) => {
  const {
    event: { credits_value },
    event,
    history,
    isAuth,
  } = props;

  const [requestModal, setRequestModal] = useState(false);
  const [tfmodal, setTfModal] = useState(false);

  return (
    <div className={`${styles.box}`}>
      <UpperRow credits={credits_value} loggedIn={isAuth} />
      <DownRow
        loggedIn={isAuth}
        event={event}
        history={history}
        setRequestModal={setRequestModal}
      />
      <CustomModal
        modal={requestModal}
        setModal={setRequestModal}
        component={() => CallRequestModal(history, setRequestModal)}
      />
    </div>
  );
};

export default EventApplication;
