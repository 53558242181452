import React from "react";
import styles from "./Card.module.css";

const Card = (props) => {
  const {
    card: { id, action_type, image, title, subtitle },
    func,
  } = props;
  return (
    <div className={styles.card} onClick={() => func(id)}>
      <div className="image-action">
        <img
          className={styles.image}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      </div>
      <div className={styles.info}>
        <p className={styles.type}>{action_type?.toUpperCase()}</p>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{subtitle}</p>
      </div>
    </div>
  );
};

export default Card;
