const domain = {
  STAGE: "https://dev.moodar.com.br/api/",
  PRODUCTION: "https://webapp.moodar.com.br/api/",
  LOCAL: "http://localhost:8000/api/",
};

// const urlPrefix = domain.LOCAL;
const urlPrefix = domain.PRODUCTION;

const serverErr = (res) => {
  switch (res.status) {
    case 400:
      return res
        .json()
        .then((response) => Promise.reject(Error(response.error)));
    case 500:
      return Promise.reject(Error("Ocorreu um erro no servidor!"));
    default:
      return Promise.reject(
        Error("Algo deu errado! Tente novamente mais tarde")
      );
  }
};

const url = (path) => urlPrefix.concat(path.join("/"));

export default async (config) => {
  const { method, path, authToken, body } = config;

  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");

  const opt: Object = {
    method,
    headers,
    credentials: "include",
    body: method !== "GET" ? JSON.stringify(body) : undefined,
  };

  return fetch(url(path), opt)
    .then((res) => (res.ok ? res.json() : serverErr(res)))
    .catch((err) => Promise.reject(err));
};
