import { EventsTypes } from "../types";

const initialState = {
  list: [],
  trending: [],
  selected_event: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EventsTypes.SAVE_TRENDING_EVENTS:
      return { ...state, trending: payload };
    case EventsTypes.SAVE_EVENTS:
      return { ...state, list: payload };
    case EventsTypes.SAVE_EVENT_DETAIL:
      return { ...state, selected_event: payload };
    case EventsTypes.CLEAR_EVENTS:
      return { ...payload };
    case EventsTypes.UPDATE_EVENTS:
      return { ...state };
    default:
      return state;
  }
};
