import React, { useState, useEffect } from "react";
import styles from "./RequestEventModal.module.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Spinner, Button } from "../../../../commom-components";
import { Colors } from "../../../../assets/color/Colors";

const RequestEvent = (props) => {
  const { isAuth, event, history, setModal } = props;

  const goToSignUp = () => {
    localStorage.setItem("requestedEvent", event.id);
    history.push({
      pathname: "/signup",
      state: { cameFromRequest: true, eventId: event.id },
    });
  };
  const goToLogin = () => {
    localStorage.setItem("requestedEvent", event.id);
    history.push({
      pathname: "/login",
      state: { cameFromRequest: true, eventId: event.id },
    });
  };
  const goToTypeForm = () => {
    localStorage.setItem("requestedEvent", event.id);
    console.log("EVENT FORM", event.typeform);
    window.location.href = `${event.typeform}`;
    history.push(event.typeform, { cameFromRequest: true });
  };

  return (
    <div className={styles.container}>
      {!isAuth ? (
        <div className={styles.content}>
          <h4 style={{ textAlign: "center", color: "black" }}>
            Para solicitar o evento <strong>{event?.title}</strong> você precisa
            estar logado na plataforma
          </h4>

          <div className={styles.optionsList}>
            <div className={styles.button}>
              <Button
                color={Colors.secondary}
                title={"Fazer login"}
                icon={"fas fa-plus-circle"}
                func={() => goToLogin()}
              ></Button>
            </div>

            <Button
              color={Colors.primary}
              title={"Fazer cadastro"}
              icon={"fas fa-plus-circle"}
              func={() => goToSignUp()}
            ></Button>
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          <h4 style={{ textAlign: "center", color: "black" }}>
            Confirma que deseja solicitar o evento{" "}
            <strong>{event?.title}</strong>?
          </h4>

          <div className={styles.optionsList}>
            <div className={styles.button}>
              <Button
                color={Colors.secondary}
                title={"Sim, confirmo"}
                icon={"fas fa-plus-circle"}
                func={() => goToTypeForm()}
              ></Button>
            </div>

            <div className={styles.button}>
              <Button
                color={Colors.primary}
                title={"Não, quero voltar"}
                icon={"fas fa-plus-circle"}
                func={() => setModal(false)}
              ></Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const {
    events: { selected_event, trending },
    auth: {
      server: { isAuthenticated: serverAuth },
      firebase: { isAuthenticated: firebaseAuth },
    },
  } = state;

  const isAuth = serverAuth && firebaseAuth;

  return { event: selected_event, trending, isAuth };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestEvent)
);
